import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from "react-helmet";
import {Nodata, NodataDesc, NodataMedia, NodataGrid, NodataItem} from "../components/Nodata"
import { Section, Container} from "../components/Section"


const ThankYouPageTemplate = ({data, location }) => { 
  return(
    <Layout location={location}>
      <Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
      <Seo title="Thank You -  EMB Metal Buildings" description="Thanks for preferring  EMB Metal Buildings for your metal building requirements." />      
      <Section textAlign="center" ept="240px" epb="120px" xpt="120px" xpb="80px" pt="120px" pb="40px" bgColor="#fff" className="section-thank">       
        <Container maxWidth="1280px">
          <Nodata>
            <NodataGrid>
              <NodataItem>
                <NodataMedia>
                  <StaticImage src="../images/thank-you.png" alt="thank-you" />
                </NodataMedia>
              </NodataItem>
              <NodataItem>
                <NodataDesc mb="30px" textAlign="left">
                  <div dangerouslySetInnerHTML={{__html: data.contentfulThanksPages.description.childMarkdownRemark.html }} />
                </NodataDesc>
              </NodataItem>
            </NodataGrid>
          </Nodata>
        </Container>
      </Section>
    </Layout>
  )
}

export default ThankYouPageTemplate

export const pageQuery = graphql`
  query thanksPageTemplateQuery($id: String!) {
    contentfulThanksPages(id: { eq: $id }) {
      description {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`